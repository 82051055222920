<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('mti.new')">
        <actions
          slot="actions"
          crud-links="mti"
          :actions="actions"
        />
        <data-form
          :mti="mti"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'mti-new',
  components: {
    DataForm,
    Actions,
  },
  data () {
    return {
      loading: false,
      actions: ['index'],
      mti: {
        id: 0,
      },
    }
  },
  methods: {
    // FIXME
    routeBuilder () {
      return 'mti/'
    },
    async submit (data) {
      this.loading = true
      const mti = data.mti
      let response = null
      try {
        response = await this.$http.post(this.routeBuilder(), mti)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      mti.id = response.data.data.id
      const preachingPoints = data.preachingPoints || []
      if (preachingPoints.length > 0) {
        const pointsData = {
          id: mti.id,
          preaching_points: {
            _ids: preachingPoints.slice(0),
          },
        }

        try {
          await this.$http.put(`mti/${mti.id}`, pointsData)
        } catch (error) {
          // console.log('Error updating data', error)
          this.loading = false
          return
        }
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.$router.push({ name: 'mtiIndex' })
    },
  },
}
</script>
